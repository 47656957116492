
'use strict';

angular.module( 'app', [] ).
run(function(){
    
})
.controller( 'IndexController', function(){
    jQuery('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = jQuery(this.hash);
            target = target.length ? target : jQuery('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                jQuery('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });
    /**
    * Scroll Magic Controllers 
    */

    var stickController = new ScrollMagic.Controller({
        globalSceneOptions: {
            triggerHook: 0.25
        }
    });
    var fadeControllerImages = new ScrollMagic.Controller({
        globalSceneOptions: {
            triggerHook: 0.6
        }
    });
    var contentController = new ScrollMagic.Controller({
        globalSceneOptions: {
            triggerHook: 0.05
        }
    });

    /**
     * Pin items
     */
    setTimeout(function () {
        var stickDuration = (jQuery(".parallax-content").height() - 500);

        new ScrollMagic.Scene({triggerElement: ".parallax-content", duration: stickDuration})
            .setPin(".index-services-title", {pushFollowers: false})
            .addTo(stickController);
        new ScrollMagic.Scene({triggerElement: ".parallax-content", duration: stickDuration})
            .setPin(".index-services-image-1", {pushFollowers: false})
            .addTo(stickController);
        new ScrollMagic.Scene({triggerElement: ".parallax-content", duration: stickDuration})
            .setPin(".index-services-image-2", {pushFollowers: false})
            .addTo(stickController);
        new ScrollMagic.Scene({triggerElement: ".parallax-content", duration: stickDuration})
            .setPin(".index-services-image-3", {pushFollowers: false})
            .addTo(stickController);
        new ScrollMagic.Scene({triggerElement: ".parallax-content", duration: stickDuration})
            .setPin(".index-services-image-4", {pushFollowers: false})
            .addTo(stickController);
    }, 100);


    /**
     * Fade in images
     */
    setTimeout(function () {
        new ScrollMagic.Scene({triggerElement: ".parallax-content-1"})
            .setClassToggle('.index-services-image-1', 'active')
            .addTo(fadeControllerImages);

        new ScrollMagic.Scene({triggerElement: ".parallax-content-2"})
            .setClassToggle('.index-services-image-2', 'active')
            .addTo(fadeControllerImages);

        new ScrollMagic.Scene({triggerElement: ".parallax-content-3"})
            .setClassToggle('.index-services-image-3', 'active')
            .addTo(fadeControllerImages);

        new ScrollMagic.Scene({triggerElement: ".parallax-content-4"})
            .setClassToggle('.index-services-image-4', 'active')
            .addTo(fadeControllerImages);
    }, 200);


    /**
     *  Fade out content on leave
     */
    setTimeout(function () {
        new ScrollMagic.Scene({triggerElement: ".parallax-content-1"})
            .setTween(".parallax-content-1", {opacity: 0, ease: Linear.easeNone})
            .addTo(contentController);
        new ScrollMagic.Scene({triggerElement: ".parallax-content-2"})
            .setTween(".parallax-content-2", {opacity: 0, ease: Linear.easeNone})
            .addTo(contentController);
        new ScrollMagic.Scene({triggerElement: ".parallax-content-3"})
            .setTween(".parallax-content-3", {opacity: 0, ease: Linear.easeNone})
            .addTo(contentController);
    }, 300);

})
// if(document.getElementById('app')) {
//     new Vue({
//         el: '#app',
//         created: function () {
//             this.initSmoothScroll();
//         },
//         mounted: function () {
//             this.initScrollMagic();
//         },
//         methods: {
//             initSmoothScroll: function () {
//                 /**
//                  * Smooth scroll
//                  */
//                 $(function () {
//                     $('a[href*="#"]:not([href="#"])').click(function () {
//                         if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
//                             var target = $(this.hash);
//                             target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
//                             if (target.length) {
//                                 $('html, body').animate({
//                                     scrollTop: target.offset().top
//                                 }, 1000);
//                                 return false;
//                             }
//                         }
//                     });
//                 });
//             },
//             initScrollMagic: function () {
//                 console.log("entro");

//                 /**
//                  * Scroll Magic Controllers
//                  */
//                 var stickController = new ScrollMagic.Controller({
//                     globalSceneOptions: {
//                         triggerHook: 0.25
//                     }
//                 });
//                 var fadeControllerImages = new ScrollMagic.Controller({
//                     globalSceneOptions: {
//                         triggerHook: 0.6
//                     }
//                 });
//                 var contentController = new ScrollMagic.Controller({
//                     globalSceneOptions: {
//                         triggerHook: 0.05
//                     }
//                 });
//                 var navController = new ScrollMagic.Controller({
//                     globalSceneOptions: {
//                         triggerHook: 0
//                     }
//                 });

//                 /**
//                  * Pin items
//                  */
//                 setTimeout(function () {
//                     var stickDuration = ($(".parallax-content").height() - 500);

//                     new ScrollMagic.Scene({triggerElement: ".parallax-content", duration: stickDuration})
//                         .setPin(".index-services-title", {pushFollowers: false})
//                         .addTo(stickController);
//                     new ScrollMagic.Scene({triggerElement: ".parallax-content", duration: stickDuration})
//                         .setPin(".index-services-image-1", {pushFollowers: false})
//                         .addTo(stickController);
//                     new ScrollMagic.Scene({triggerElement: ".parallax-content", duration: stickDuration})
//                         .setPin(".index-services-image-2", {pushFollowers: false})
//                         .addTo(stickController);
//                     new ScrollMagic.Scene({triggerElement: ".parallax-content", duration: stickDuration})
//                         .setPin(".index-services-image-3", {pushFollowers: false})
//                         .addTo(stickController);
//                     new ScrollMagic.Scene({triggerElement: ".parallax-content", duration: stickDuration})
//                         .setPin(".index-services-image-4", {pushFollowers: false})
//                         .addTo(stickController);
//                 }, 100);


//                 /**
//                  * Fade in images
//                  */
//                 setTimeout(function () {
//                     new ScrollMagic.Scene({triggerElement: ".parallax-content-1"})
//                         .setClassToggle('.index-services-image-1', 'active')
//                         .addTo(fadeControllerImages);

//                     new ScrollMagic.Scene({triggerElement: ".parallax-content-2"})
//                         .setClassToggle('.index-services-image-2', 'active')
//                         .addTo(fadeControllerImages);

//                     new ScrollMagic.Scene({triggerElement: ".parallax-content-3"})
//                         .setClassToggle('.index-services-image-3', 'active')
//                         .addTo(fadeControllerImages);

//                     new ScrollMagic.Scene({triggerElement: ".parallax-content-4"})
//                         .setClassToggle('.index-services-image-4', 'active')
//                         .addTo(fadeControllerImages);
//                 }, 200);


//                 /**
//                  *  Fade out content on leave
//                  */
//                 setTimeout(function () {
//                     new ScrollMagic.Scene({triggerElement: ".parallax-content-1"})
//                         .setTween(".parallax-content-1", {opacity: 0, ease: Linear.easeNone})
//                         .addTo(contentController);
//                     new ScrollMagic.Scene({triggerElement: ".parallax-content-2"})
//                         .setTween(".parallax-content-2", {opacity: 0, ease: Linear.easeNone})
//                         .addTo(contentController);
//                     new ScrollMagic.Scene({triggerElement: ".parallax-content-3"})
//                         .setTween(".parallax-content-3", {opacity: 0, ease: Linear.easeNone})
//                         .addTo(contentController);
//                 }, 300);

//                 /**
//                  *   Nav bar
//                  */
//                 new ScrollMagic.Scene({triggerElement: ".main", offset: 50})
//                     .setClassToggle(".nav", "nav-white")
//                     .addTo(navController);

//                 /**
//                  * Parallax
//                  */
//                 /*
//                  // init controller
//                  var controller = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "100%"}});

//                  // build scenes
//                  new ScrollMagic.Scene({triggerElement: ".index-quotes"})
//                  .setTween(".background-parallax", {y: "-60%", ease: Linear.easeNone})
//                  .addIndicators()
//                  .addTo(controller);
//                  */

//             }
//         }
//     });
// }

// if(document.getElementById('pages')) {
//     new Vue({
//         el: '#pages',
//         created: function () {
//             this.initSmoothScroll();
//         },
//         mounted: function () {
//             this.initScrollMagic();
//         },
//         methods: {
//             initSmoothScroll: function () {
//                 $(function () {
//                     $('a[href*="#"]:not([href="#"])').click(function () {
//                         if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
//                             var target = $(this.hash);
//                             target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
//                             if (target.length) {
//                                 $('html, body').animate({
//                                     scrollTop: target.offset().top
//                                 }, 1000);
//                                 return false;
//                             }
//                         }
//                     });
//                 });
//             },
//             initScrollMagic: function () {
//                 var navController = new ScrollMagic.Controller({
//                     globalSceneOptions: {
//                         triggerHook: 0
//                     }
//                 });
//                 new ScrollMagic.Scene({triggerElement: ".main", offset: 50})
//                     .setClassToggle(".nav", "nav-white")
//                     .addTo(navController);
//             }
//         }
//     });
// }

